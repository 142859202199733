/* eslint-disable */
import loopback from '@/services/loopback';

function filterKey(k) {
    return k ? `/${k}` : ''
}

export default {

    model: '',

    setModel(model) {
        this.model = model;
    },

    setId(id) {
        this.id = id;
    },

    get({ filter }) {
        return loopback.get(`/${this.model}`, { params: { filter } });
    },

    find({ id, filter }) {
        id = id ? id: '';
        return loopback.get(`/${this.model}/${id}${this.relation}`, { params: { filter } });
    },

    findOne(filter) {
        return loopback.get(`/${this.model}/findOne`, { params: { filter } });
    },

    findById({ id, fk, filter }) {
        fk = filterKey(fk)
        return loopback.get(`/${this.model}/${id}${this.relation}/${fk}`, { params: { filter } });
    },

    create({id}, data) {
        return loopback.post(`/${this.model}/${id}${this.relation}`, data);
    },

    updateById({ id, fk }, data) {
        return loopback.put(`/${this.model}/${id}${this.relation}/${fk}`, data);
    },

    update({ id }, data){
        return loopback.put(`/${this.model}/${id}${this.relation}`, data);
    },

    destroyById({ id, fk }) {
        // TODO added a / but is innecesary
        return loopback.delete(`/${this.model}/${id}${this.relation}/${fk}`);
    }
}
