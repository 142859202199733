<template>
    <div
        class="z-input"
        :class="{
            'z-input-has-value': hasValue,
            'z-input-append-icon': appendIcon,
            'z-input-prepend-icon': prependIcon,
            'z-input-valid': isStateValid,
            'z-input-invalid': isStateInvalid,
            'z-input-label-static': labelVariant === 'static'
        }"
    >
        <z-icon
            v-if="prependIcon"
            :name="prependIcon"
            :variant="prependIconVariant"
            role="button"
            class="prepend-icon"
            size="20"
            data-test-id="prepend-icon"
            v-tooltip.top="prependIconTootltip"
            @click.native="$emit('prepend-icon-click', $event)"
        />
        <slot name="input">
            <input
                :id="id"
                :type="type"
                :value="value"
                :disabled="disabled"
                @input="handleInput"
                v-bind="$attrs"
                :class="disabled ? 'disabled' : null"
                ref="input"
            />
        </slot>
        <label v-if="label || $scopedSlots['label']" :for="id" :class="labelVariant">
            <slot name="label">
                {{ label }}
            </slot>
        </label>
        <z-icon
            v-if="isStateValid"
            name="Check"
            size="20"
            class="state-icon"
            color="#0a937f"
            data-test-id="state-valid-icon"
        />
        <z-icon
            v-if="appendIcon"
            :name="appendIcon"
            :variant="appendIconVariant"
            role="button"
            class="append-icon"
            size="20"
            data-test-id="append-icon"
            v-tooltip.top="appendIconTootltip"
            @click.native="$emit('append-icon-click', $event)"
        />
    </div>
</template>

<script>
import ZIcon from "./ZIcon.vue";

export default {
    components: {
        ZIcon
    },
    props: {
        appendIcon: {
            type: String,
            default: null
        },
        appendIconVariant: {
            type: String,
            default: "normal",
            validator: val => ["normal", "active"].indexOf(val) > -1
        },
        appendIconTootltip: {
            type: String,
            default: ""
        },
        prependIcon: {
            type: String,
            default: null
        },
        prependIconVariant: {
            type: String,
            default: "normal",
            validator: val => ["normal", "active"].indexOf(val) > -1
        },
        prependIconTootltip: {
            type: String,
            default: ""
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: ""
        },
        labelVariant: {
            type: String,
            default: "animated",
            validator: val => ["animated", "static"].indexOf(val) > -1
        },
        state: {
            type: Boolean,
            default: null
        },
        type: {
            type: String,
            default: "text",
            validator: val => ["text", "tel", "email", "password", "number"].indexOf(val) > -1
        },
        value: {
            type: [Number, String],
            default: ""
        },
        autocomplete: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasValue() {
            return this.value?.length > 0 || this.inputValue?.length > 0;
        },
        inputValue() {
            return this.inputSlot?.value || "";
        },
        inputSlot() {
            return this.autocomplete
                ? this.$scopedSlots?.input()[0]?.context?.$refs?.autocomplete?.$refs?.input
                : this.$refs?.input;
        },
        isStateValid() {
            return this.state === true;
        },
        isStateInvalid() {
            return this.state === false;
        }
    },

    methods: {
        handleInput(event) {
            const value = event.target.value;
            if (this.type === "tel") {
                const formattedNumner = this.formatPhone(value);
                this.$emit("input", formattedNumner);
            } else {
                this.$emit("input", value);
            }
        },
        formatPhone(value) {
            const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";
$font-size-sm: 12px;
$font-size-xs: 10px;

.z-input {
    position: relative;
    border-radius: 6px;

    input {
        display: block;
        line-height: 2.4em;
        margin: 0;
        padding-left: 16px;
        width: 100%;
        background-color: $white;
        border: 1px solid $link-water;
        border-radius: 6px;
        font-weight: 500;
        box-sizing: border-box;
        color: $comet;
        font-size: $font-size-sm;
        height: 44px;
    }

    .disabled {
        background-color: $solitude;
        color: $comet;
    }

    input:focus {
        border: 1px solid $zubut-blue;
        border-radius: 6px;
        //box-shadow: 0px 0px 2px rgba($zubut-blue, 0.25);
    }

    input:focus-visible {
        outline: none;
    }

    label.animated {
        display: block;
        position: absolute;
        bottom: 2.3rem;
        color: $comet;
        transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        left: 12px;
        top: 13px;
        font-size: $font-size-sm;
        padding-left: 4px;
        padding-right: 4px;
        font-weight: 500;
        pointer-events: none;
    }

    label.static {
        position: absolute;
        top: -24px;
        font-weight: 500;
        font-size: 12px;
    }

    &.z-input-label-static {
        margin-top: 40px;
    }
}

/* Float label when is focused */
.z-input input:focus + label.animated,
.z-input.z-input-has-value input + label.animated {
    z-index: 2;
    font-size: $font-size-xs;
    transform: translateY(-19px);
    transition: 0.2s ease-in-out transform;

    &:before {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: white;
        left: 0px;
        top: 6px;
        z-index: -1;
        padding-left: 1px;
    }
}

/* Color label when is focused */
.z-input input:focus + label.animated {
    color: $zubut-blue;
}

/* Append icon styles */
.z-input.z-input-append-icon {
    input {
        padding-right: 38px;
    }

    .append-icon {
        position: absolute;
        display: block;
        right: $font-size-xs;
        top: 6px;
    }
}

.z-input.z-input-prepend-icon {
    input {
        padding-left: 38px;
    }

    .prepend-icon {
        position: absolute;
        display: block;
        left: $font-size-xs;
        top: 6px;
    }
}

/* State validation styles */
.z-input.z-input-append-icon.z-input-valid {
    input {
        padding-right: 68px;
    }

    .state-icon {
        position: absolute;
        display: block;
        right: 40px;
        top: 12px;
    }
}

.z-input.z-input-prepend-icon.z-input-valid {
    input {
        padding-right: 38px;
    }

    .state-icon {
        position: absolute;
        display: block;
        right: $font-size-xs;
        top: 12px;
    }
}

.z-input.z-input-valid:not(.z-input-append-icon, .z-input-prepend-icon) {
    input {
        padding-right: 38px;
    }

    .state-icon {
        position: absolute;
        display: block;
        right: $font-size-xs;
        top: 12px;
    }
}

.z-input.z-input-invalid {
    input {
        border: 1px solid $medium-carmine;
    }

    input:focus {
        border: 1px solid $medium-carmine;
        border-radius: 6px;
        box-shadow: 0 0 0 2px rgba($medium-carmine, 0.05);
    }

    input:focus + label {
        color: $medium-carmine;
    }
}
</style>
