import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Clients");

const Clients = Object.assign(
    {},
    {
        relation: "",

        /**
         * Register client
         * @param {string} name
         * @param {string} email
         * @param {string} phone
         * @param {number} type
         * @param {string} password
         * @param {string} contact
         * @param {number} conversionChannel Source where client heard from us
         */
        register(data) {
            return loopback.post("/Clients/register", { data });
        },

        /**
         * Pre register client
         * @param {string} name
         * @param {string} email
         * @param {string} phone
         * @param {string} businessLine
         * @param {Array}  serviceTypes
         * @param {number} conversionChannel Source where client heard from us
         */
        preRegister(data) {
            return loopback.post("/Clients/preregister", { data });
        },

        /**
         * Sends new prospect's data to slack channel
         * @param {string} firstName
         * @param {string} lastName
         * @param {string} phone
         * @param {string} companyName
         * @param {string} companyType
         * @param {string} avgDeliveries Average number of deliveries in a month
         * @param {string} [website] Company website
         * @param {number} conversionChannel Source where client heard from us
         * @param {number} salesChannel Source where client send its products
         */
        newProspect(data) {
            return loopback.post("/Clients/newProspect", data);
        }
    },
    modelObject
);

export default Clients;
