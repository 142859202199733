const NO_REFERENCE = "Sin referencia";
const COMERCIAL = "Comercial";
const REFERENCE = "Referencia";
const GOOGLE = "Google";
const FACEBOOK = "Facebook";
const LINKEDIN = "Linkedin";

const NUM_NO_REFERENCE = 0;
const NUM_COMERCIAL = 1;
const NUM_REFERENCE = 2;
const NUM_GOOGLE = 3;
const NUM_FACEBOOK = 4;
const NUM_LINKEDIN = 5;

const get = {};
get[NUM_NO_REFERENCE] = NO_REFERENCE;
get[NUM_COMERCIAL] = COMERCIAL;
get[NUM_REFERENCE] = REFERENCE;
get[NUM_GOOGLE] = GOOGLE;
get[NUM_FACEBOOK] = FACEBOOK;
get[NUM_LINKEDIN] = LINKEDIN;

const options = [
    { value: NUM_COMERCIAL, text: COMERCIAL },
    { value: NUM_REFERENCE, text: REFERENCE },
    { value: NUM_GOOGLE, text: GOOGLE },
    { value: NUM_FACEBOOK, text: FACEBOOK },
    { value: NUM_LINKEDIN, text: LINKEDIN }
];

export default {
    get,
    options,
    NO_REFERENCE,
    COMERCIAL,
    REFERENCE,
    GOOGLE,
    FACEBOOK,
    LINKEDIN,
    NUM_NO_REFERENCE,
    NUM_COMERCIAL,
    NUM_REFERENCE,
    NUM_GOOGLE,
    NUM_FACEBOOK,
    NUM_LINKEDIN
};
